<template>
  <div class="tw-file">
    <FilePond
      ref="pond"
      :files="file"
      :class="{'tw-dont-remove-action' : !hasRemove}"
      name="file"
      :label-idle="labelIdle"
      :accepted-file-types="props.config?.isAllowImg ? config.selectedImgType : config.selectedVideoType"
      :allow-multiple="hasImage ? config.multiple : false"
      :allow-remove="true"
      @addfile="onAddFile"
      @removefile="onRemoveFile"
    />
  </div>
</template>
<script lang="ts" setup>
import { type PropType, computed } from "vue" 
import { useTranslate } from '@shared/composable/useTranslate';
import vueFilePond from "vue-filepond";
import { setOptions } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import type { PageOptions } from '../../types/model';
import type { ElementOptions } from '../../types/options';
import { $wait } from '@shared/utils/wait';

const { translate } = useTranslate();

const props = defineProps({
  locale: { type: String, default: '' },
  file: { type: Array as PropType<string[]>, default: () => [] },
  config: { type: Object as PropType<ElementOptions['file']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  canSubmit: {type: Boolean, default: false}
});

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginFileValidateSize
);

setOptions({
  imagePreviewHeight: 120,
  allowImageResize: true,
  imageResizeTargetWidth: 253,
  imageResizeTargetHeight: 120,
  maxFileSize: props.config.isAllowImg ? `${props.config.imgSize}MB` : `${props.config.videoSize}MB`
})

const emit = defineEmits(['update:file']);

const onAddFile = (error: any, file: any) => {
  const newFiles = [...props.file, file]
  emit('update:file', newFiles);
}

const onRemoveFile = (error: any, file: any) => {
  if (typeof file.source === 'string') {
    return emit('update:file', props.file.filter((item: any) => item.source !== file.source));
  }
  return emit('update:file', props.file.filter((item: any) => item.id !== file.id));
}

const hasRemove = computed(() => {
  return !$wait?.is(['nextQuestion', 'prevQuestion'])
})

const hasVideo = computed(() => {
  return props.config?.isAllowVideo
    ? `<span><span class="font-bold">${
        props.config.videoSize > 1000
          ? (props.config.videoSize / 1000).toFixed(1) + 'GB'
          : props.config.videoSize + 'MB'
      }</span> ${translate('generate.file.video', props.locale)}</span>`
    : ''
})

const hasImage = computed(() => {
  return props.config?.isAllowImg
    ? `<span class="font-bold">${props.config.imgSize}MB</span> ${translate('generate.file.photos', props.locale)}`
    : ''
})

const labelIdle = computed(() => {
  return `
    <div class="flex flex-col pt-[24px] pb-24 !text-[12px]">
        <div class="flex justify-center !text-[14px] font-semibold">
          ${translate('generate.file.drag', props.locale)}
        </div>
        <div class="flex justify-center !text-[11px] text-neutral-500 mt-8 uppercase">
          ${translate('generate.file.OR', props.locale)}
        </div>
        <div class='filepond--label-action'> ${translate('generate.file.uplaodFile', props.locale)} </div>
        <span class="mt-[24px] text-[#818A9C]">
          ${translate('generate.file.fileSize', props.locale)}: ${hasVideo.value} ${hasImage.value && hasVideo.value ? '<span>and </span>' : ''} ${hasImage.value}
        </span>
    </div>`
})


</script>
<style lang="postcss">
@import "filepond/dist/filepond.min.css";
@import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
.filepond--root{
  @apply md:w-[485px] w-full;
}
.filepond--root .filepond--drop-label {
  @apply min-h-[170px];
}
.filepond--panel-root{

  background-color: #F6F7F9 !important;
  border-radius: 10px;
  &:first-child{
    border: 1px dashed #C3C9D5 !important;
  }
  &:nth-child(2n){
    border-left: 1px dashed #C3C9D5 !important;
    border-right: 1px dashed #C3C9D5 !important;
  }
  &:last-child{
    border: 1px dashed #C3C9D5 !important;
  }
}

.filepond--credits{
  @apply hidden
}

.tw-editor__screen {
  &.mobile {
    .filepond--root, .filepond--panel-root, .tw-file{
      @apply w-full;
    }
  }
}
.filepond--label-action{
  @apply bg-[#0D0626] text-white min-w-[113px] py-8 m-auto mt-8 rounded-8 no-underline text-12 capitalize font-semibold flex justify-center;
}
.filepond--item {
  @apply md:w-[calc(50%-0.5em)] w-full;
}
.tw-dont-remove-action .filepond--action-remove-item{
  @apply invisible;
}
</style>
